import React from "react";
import PropTypes from "prop-types";
import { CardBody } from "reactstrap";
import { Skeleton } from "@mui/material";

function Table(props) {
  const { isLoading } = props;
  return isLoading ? <CardBody className="py-0">
    <div className="table-responsive my-4">
      <table className={props.tableClasses.table}>
        {props.noHeader ? null : (
          <thead className={props.tableClasses.thead}>
            <tr className={props.tableClasses.tr}>
              {props.columns.map((item) => {
                return (
                  <th
                    key={item.id}
                    className={item.className}
                    onClick={item.onHeadingClick ? item.onHeadingClick : null}
                    style={{ fontSize: "16px" }}
                  >
                    <Skeleton width={100} height={30} />
                  </th>
                );
              })}
            </tr>
          </thead>
        )}
        <tbody className={props.tableClasses.tbody}>
          {[1, 2, 3, 4, 5, 7, 6, 41, 3234, 327, 328, 54544].map((record, index) => (
            <tr
              key={index}
              className={
                props.rowClassName
                  ? props.rowClassName
                  : record.changed
                    ? "font-weight-bolder bg-threshold text-dark"
                    : ""
              }
            >
              {props.columns.map((col) => (
                <td
                  key={col.id}
                  className={col.className}
                  onClick={() =>
                    col.onClick && !props.onRowClick
                      ? col.onClick(record)
                      : null
                  }
                >
                  <Skeleton width={100} height={30} />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </CardBody> :
    (
      <CardBody className="py-0">
        <div className="table-responsive my-4">
          <table className={props.tableClasses.table}>
            {props.noHeader ? null : (
              <thead className={props.tableClasses.thead}>
                <tr className={props.tableClasses.tr}>
                  {props.columns.map((item) => {
                    return (
                      <th
                        key={item.id}
                        className={item.className}
                        onClick={item.onHeadingClick ? item.onHeadingClick : null}
                        style={{ fontSize: "16px" }}
                      >
                        {item.title}
                      </th>
                    );
                  })}
                </tr>
              </thead>
            )}
            <tbody className={props.tableClasses.tbody}>
              {props.values.map((record, index) => (
                <tr
                  key={index}
                  className={
                    props.rowClassName
                      ? props.rowClassName
                      : record.changed
                        ? "font-weight-bolder bg-threshold text-dark"
                        : ""
                  }
                  onClick={() =>
                    props.onRowClick ? props.onRowClick(record) : null
                  }
                >
                  {props.columns.map((col) => (
                    <td
                      key={col.id}
                      className={col.className}
                      onClick={() =>
                        col.onClick && !props.onRowClick
                          ? col.onClick(record)
                          : null
                      }
                    >
                      {col.render ? col.render(record, index) : record[col.id]}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </CardBody>
    );
}

Table.propTypes = {
  columns: PropTypes.array,
  values: PropTypes.array,
  rowClassName: PropTypes.string,
  onRowClick: PropTypes.func,
  tableClasses: PropTypes.object,
  noHeader: PropTypes.bool,
};

Table.defaultProps = {
  columns: [
    {
      id: "test",
      title: "test",
      className: "",
      onClick: null,
      render: null,
    },
  ],
  values: [
    {
      test: "test",
    },
  ],
  rowClassName: "",
  onRowClick: null,
  tableClasses: {
    table: "",
    thead: "",
    tr: "",
  },
  noHeader: false,
};

export default Table;
