import React, { useEffect, useState } from "react";
import Filters from "views/shared/Filters";
import { Container, Card, CardBody, Spinner } from "reactstrap";
import ChartContainer from "./ChartsContainer.jsx";
import { useParams } from "react-router-dom";
import PerDayFilters from "views/shared/PerDayFilters.jsx";
import DataCard from "components/DataCard.jsx";
import axios from "axios";
import { baseUrl } from "constants/url.js";
import authHeader from "services/auth-header.js";
import Table from "views/shared/Table.jsx";
import { Grid, Grid2, TablePagination } from "@mui/material";

import PacksProducedImage from "../../../assets/img/icons/packs_produced_icon_w.png";
import idleTimeImage from "../../../assets/img/icons/idleTime.png";
import runtimeimage from "../../../assets/img/icons/runtime.png";
import WeightPerMinutePerOperatorIcon from "../../../assets/img/icons/weight-per-minute-per-operator-icon.png";
import { Box } from "@material-ui/core";

// Day | Machine | Tool | Product | Number of Packs Packed | Average Packs Per Minute | The time the machine was running | Idle time
const tableColumns = [
  {
    id: "Day",
    title: "Day",
    className: "sort table-data-font",
    onClick: null,
    render: null,
  },
  {
    id: "Machine",
    title: "Machine",
    className: "sort table-data-font",
    onClick: null,
    render: null,
  },
  {
    id: "Product",
    title: "Product",
    className: "sort table-data-font",
    onClick: null,
    render: null,
  },
  {
    id: "Tool",
    title: "Tool",
    className: "sort table-data-font",
    onClick: null,
    render: null,
  },
  {
    id: "Idle Time",
    title: "Idle Time",
    className: "sort table-data-font",
    onClick: null,
    render: null,
  },
  {
    id: "Number of Packs Packed",
    title: "Number of Packs Packed",
    className: "sort table-data-font",
    onClick: null,
    render: null,
  },
  {
    id: "Run Time",
    title: "Run Time",
    className: "sort table-data-font",
    onClick: null,
    render: null,
  },
];

function DaySummary({ clientId }) {
  const params = useParams();
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [count, setCount] = useState(0);

  const [resData, setResData] = useState({
    isLoading: true,
    metrics: {
      'Packs Produced': '0',
      'Idle Time Total': '0',
      'Run Time Total': '0',
      'OEEE': '0',
    },
    tableData: "Loading...",
  })

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [filtersData, setFiltersData] = useState({
    // start_date: "2023-01-13T11:35:04.00Z",
    start_date: yesterday.toISOString(),
    // end_date: "2023-01-13T11:35:04.30Z",
    end_date: today.toISOString(),
    product: "All",
    machine: "All",
    tool: "All",
  });

  useEffect(() => {
    if (filtersData.start_date && filtersData.end_date) {
      axios
        .post(
          `${baseUrl}/api/admin/day-summary`,
          {
            start_date: filtersData.start_date,
            end_date: filtersData.end_date,
            clientId,
            machine: filtersData.machine,
            product: filtersData.product,
            tool: filtersData.tool,
          },
          {
            headers: authHeader(),
          }
        )
        .then((res) => {
          setResData({
            isLoading: false,
            metrics: res.data.metrics,
            // tableData: res.data.tableData
            tableData: res.data.groupedData.map(el => ({
              ...el,
              'Run Time': secondsToMinutes(el['Run Time']),
              'Idle Time': secondsToMinutes(el['Idle Time']),
            }))
          });
          setCount(res.data.tableData.length)
        })
        .catch((err) => {
          setResData({
            isLoading: false,
            metrics: {
              'Packs Produced': 0,
              'Idle Time Total': 0,
              'Run Time Total': 0,
              'OEEE': 0,
            },
            tableData: []
          });
        });
    }
  }, [filtersData])


  const handleApplyFilters = (filterData) => {
    setFiltersData({ ...filterData });
  };

  const secondsToHoursMinutes = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return `${hours}h ${minutes}m`;
  }

  const secondsToMinutes = (seconds) => {
    return Math.floor(seconds / 60);
  }

  return (
    <Container fluid>
      <Card className="mb-4">
        <CardBody className="rounded px-5 py-4">
          <PerDayFilters
            filtersData={filtersData}
            handleApplyFilters={handleApplyFilters}
            clientId={clientId}
            machineId={params.machineId}
          />
        </CardBody>
      </Card>
      <Grid2
        container
        // direction="column"
        // justifyContent="space-between"
        alignItems="center"
        style={{ minHeight: "100%" }}
        spacing={1}
      >

        <Grid2 item xs={3} md={6} lg={3} sx={{ flexGrow: 1 }}>
          <DataCard
            isLoading={resData.isLoading}
            message={"Packs Produced"}
            cardImage={PacksProducedImage}
            cardData={{
              title: "Packs Produced",
              // durationDays: cardsData.durationDays,
              count: (resData.metrics['Packs Produced']),
              // sinceValue: cardsData.totalGa.sinceValue,
            }}
            toolTipData={{
              id: "tga-card-tooltip",
              message: "Packs Produced",
            }}
            revert_order
          />
        </Grid2>

        <Grid2 item xs={3} md={6} lg={3} sx={{ flexGrow: 1 }}>
          <DataCard
            isLoading={resData.isLoading}
            message={"Idle Time Total"}
            cardImage={idleTimeImage}
            cardData={{
              title: "Idle Time Total",
              // durationDays: cardsData.durationDays,
              count: secondsToHoursMinutes(resData.metrics['Idle Time Total']),
              // sinceValue: cardsData.totalGa.sinceValue,
            }}
            toolTipData={{
              id: "tga-card-tooltip2",
              message: "Idle Time Total",
            }}
            revert_order
          />
        </Grid2>
        <Grid2 item xs={3} md={6} lg={3} sx={{ flexGrow: 1 }}>

          <DataCard
            isLoading={resData.isLoading}
            message={"Run Time Total"}
            cardImage={runtimeimage}
            cardData={{
              title: "Run Time Total",
              // durationDays: cardsData.durationDays,
              count: secondsToHoursMinutes(resData.metrics['Run Time Total']),
              // sinceValue: cardsData.totalGa.sinceValue,
            }}
            toolTipData={{
              id: "tga-card-tooltip3",
              message: "Run Time Total",
            }}
            revert_order
          />
        </Grid2>
        <Grid2 item xs={3} md={6} lg={3} sx={{ flexGrow: 1 }}>

          <DataCard
            isLoading={resData.isLoading}
            message={"OEEE"}
            cardImage={WeightPerMinutePerOperatorIcon}
            cardData={{
              title: "OEEE",
              // durationDays: cardsData.durationDays,
              count: (resData.metrics['OEEE']),
              // sinceValue: cardsData.totalGa.sinceValue,
            }}
            toolTipData={{
              id: "tga-card-tooltip4",
              message: "OEEE",
            }}
            revert_order
          />
        </Grid2>
      </Grid2>
      <Box sx={{ height: 20 }} />
      {

        resData.tableData.length > 0 ? (
          <>
            <h3 style={{
              color: "#0ca6f2"
            }}>Per Day Summary</h3>
            <Table
              values={resData.tableData.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )}
              isLoading={resData.tableData === "Loading..."}
              columns={tableColumns}
              tableClasses={{
                table:
                  "table align-items-center table-flush table-sm border-bottom text-white ",
                thead: "",
                tbody: "list table-td-color",
              }}
            />
            <TablePagination
              sx={{
                '& .MuiTablePagination-selectLabel': {
                  marginBottom: 0
                },
                '& .MuiTablePagination-displayedRows': {
                  marginBottom: 0
                }
              }}
              component="div"
              className="text-muted"
              count={count}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />{" "}
          </>
        ) : (
          <span className="m-4">No Data Found</span>
        )}
    </Container>
  );
}

export default DaySummary;
