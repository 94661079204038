import React, { useState, useEffect } from "react";
import { Col, FormGroup, Row, Spinner } from "reactstrap";
import Table from "views/shared/Table";
import { TablePagination } from "@material-ui/core";
import axios from "axios";
import { baseUrl } from "constants/url";
import authHeader from "services/auth-header";
import DateRangeSelector from "views/shared/DateRangeSelector";
import Select from "react-select";

const customStyles = {
  input: (base) => ({
    ...base,
    color: "white",
  }),
  singleValue: (base) => ({
    ...base,
    color: "white",
  }),
  placeholder: (base) => ({
    ...base,
    color: "white",
  }),
  control: (base, state) => ({
    ...base,
    // background: "#023950",
    // background: "#737977",
    background: "#19312e",
    // match with the menu
    borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
    // Overwrittes the different states of border
    // borderColor: state.isFocused ? "yellow" : "green",
    borderColor: state.isFocused ? "#0ca6f2" : "#8898aa",
    // Removes weird border around container
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "#0ca6f2" : "#0ca6f2",
    },
  }),
  menu: (base) => ({
    ...base,
    // background: "#737977",
    background: "#19312e",
    color: "white",
    // override border radius to match the box
    border: "1px solid #8898aa",
    borderRadius: 5,
    // kill the gap
    marginTop: 0,
  }),
  menuList: (base, state) => ({
    ...base,
    // kill the white space on first and last option
    padding: 0,
    "::-webkit-scrollbar": {
      display: "none",
    },
    // overflow: 'hidden'
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    // const color = chroma(data.color);
    return {
      ...styles,
      // backgroundColor: isFocused ? "#2462ad" : null,
      backgroundColor: isFocused ? "#0ca6f2" : null,
      color: isFocused ? "black" : "white",
      // color: "#333333",
    };
  },
};

function TableAlarmInfo({ clientId, machineId, title, filter }) {
  const [tableData, setTableData] = useState([]);
  // const [filterData, setFilterData] = useState([])
  // const [machineList, setMachineList] = useState([])
  const [groupList, setGroupList] = useState([]);
  const [descriptionList, setDescriptionList] = useState([]);
  const today = new Date(filter.start_date);
  const endDate = new Date(filter.end_date);
  // yesterday.setDate(yesterday.getDate() - 7);

  // const [currentMachine, setCurrentMachine] = useState({ value : 'All' , label  :'All'})
  // const [currentGroup, setCurrentGroup] = useState({ value : 'All' , label  :'All'})

  const [filtersData, setFiltersData] = useState({
    // start_date: "2023-01-13T11:35:04.00Z",
    start_date: today.toISOString(),
    // end_date: "2023-01-13T11:35:04.30Z",
    end_date: endDate.toISOString(),
    group: "All",
    description: "All",
  });

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [count, setCount] = useState(0);

  // useEffect(() => {

  //   setFilterData(tableData);

  //   if (typeof tableData === "string") return;
  //   let machines = tableData.map((item) => {
  //     return { value: item.Machine_ID, label: item.Machine_ID };
  //   })

  //   machines = [...new Set(machines.map(JSON.stringify))].map(JSON.parse);
  //   setMachineList([{value : 'All' , label  :'All'} ,  ...machines]);

  //   // let groups = tableData.map((item) => {
  //   //   return { value: item.Group, label: item.Group };
  //   // })

  //   // groups = [...new Set(groups.map(JSON.stringify))].map(JSON.parse);

  //   // setGroupList( [ {value : 'All' , label  :'All'}, ...groups]);

  // }, [tableData])

  const fetchTableData = (page, rowsPerPage, clientId, machineId) => {
    axios
      .get(`${baseUrl}/api/alarm_info/${page}/${rowsPerPage}`, {
        params: { ...filtersData, clientId, machineId },
        headers: authHeader(),
      })
      .then((res) => {
        // let groups = res.data.groups.map((item) => {
        //   return { value: item, label: item };
        // });
        // let descriptions = res.data.descriptions.map((item) => {
        //   return { value: item, label: item };
        // });
        // setGroupList([{ value: "All", label: "All" }, ...groups]);
        // setDescriptionList([{ value: "All", label: "All" }, ...descriptions]);
        setTableData(res.data.alarmData);
        setCount(res.data.count);
      })
      .catch((err) => {
        setTableData([]);
        // setGroupList([{ value: "All", label: "All" }]);
        // setDescriptionList([{ value: "All", label: "All" }]);
      });
  };

  useEffect(() => {
    console.log("useEffect1")
    setTableData("Loading...");
    axios
      .get(`${baseUrl}/api/alarm_info/${page}/${rowsPerPage}`, {
        params: { start_date: filtersData.start_date, end_date: filtersData.end_date, clientId, machineId },
        headers: authHeader(),
      })
      .then((res) => {
        let groups = res.data.groups.map((item) => {
          return { value: item, label: item };
        });
        let descriptions = res.data.descriptions.map((item) => {
          return { value: item, label: item };
        });
        setGroupList([{ value: "All", label: "All" }, ...groups]);
        setDescriptionList([{ value: "All", label: "All" }, ...descriptions]);
        setTableData(res.data.alarmData);
        setCount(res.data.count);
      })
      .catch((err) => {
        setTableData([]);
        setGroupList([{ value: "All", label: "All" }]);
        setDescriptionList([{ value: "All", label: "All" }]);
      });
  }, [filtersData.start_date, filtersData.end_date, machineId, clientId]);

  useEffect(() => {
    setTableData("Loading...");
    console.log("useEffec2")
    fetchTableData(
      page,
      rowsPerPage,
      clientId,
      machineId,
    );
  }, [page, rowsPerPage, clientId, filtersData, machineId]);

  const tableColumns = [
    {
      id: "TIMESTAMP",
      title: "Date/Time",
      className: "sort table-data-font",
      onClick: null,
      render: null,
    },
    {
      id: "Machine_ID",
      title: "Machine",
      className: "sort table-data-font",
      onClick: null,
      render: null,
    },
    {
      id: "Group",
      title: "Group",
      className: "sort table-data-font",
      onClick: null,
      render: null,
    },
    {
      id: "Alarm_Desc",
      title: "Description",
      className: "sort table-data-font",
      onClick: null,
      render: null,
    },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleApplyFromDateFilter = (startDate, endDate) => {
    console.log("startDate", startDate);
    console.log("endDate", endDate);
    // setFiltersData((prevState) => {
    //   return {
    //     ...prevState,
    //     start_date : startDate,
    //     end_date : endDate,
    //   };
    // });
  };

  const handleDateRange = (start_date, end_date) => {
    console.log("start_date", start_date);
    console.log("end_date", end_date);
    setFiltersData((prevState) => {
      return {
        ...prevState,
        start_date,
        end_date,
      };
    });
  };

  // const handleMachineSelect = (machineId) => {
  //   setCurrentMachine({
  //     value : machineId,
  //     label : machineId
  //   });
  //   if(machineId === 'All')
  //     setFilterData(tableData);
  //   else setFilterData(
  //     tableData.filter((item) => item.Machine_ID === machineId )
  //   );
  // };
  // const handleGroupSelect = (groupId) => {
  //   if (groupId === currentGroup.value) return;
  //   setCurrentGroup({
  //     value: groupId,
  //     label: groupId,
  //   });
  //   setPage(0);
  //   fetchTableData(0, rowsPerPage, clientId, machineId, groupId);

  //   // if(groupId === 'All')
  //   //   setFilterData(tableData);
  //   // else setFilterData(
  //   //   tableData.filter((item) => item.Group === groupId )
  //   // );
  // };

  const handleFilterChange = (name, value) => {
    console.log(name, value);
    if (filtersData[name] !== value)
      setPage(0);

    setFiltersData((prevState) => {
      return {
        ...prevState,
        [name]: value
      };
    });
  }

  return (
    <span className="mb-4">
      <h2 className="px-4 pt-4" style={{ color: "#0ca6f2" }}>
        {title}
      </h2>
      <Row className="mb-2">
        <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={2}>
          <DateRangeSelector
            filtersData={filtersData}
            handleDateRange={handleDateRange}
            handleApplyFromDateFilter={handleApplyFromDateFilter}
          // width='320px'
          />
        </Col>
        {/* <Col xs={12} sm={12} md={3} lg={4} xl={4} xxl={2}>
          <FormGroup>
            <label className='form-control-label textWhite d-block'>
              Select Machine:
            </label>
            <Select
              placeholder='Select Machine...'
              name='currentMachine'
              value={currentMachine}
              onChange={e => handleMachineSelect(e.value)}
              styles={customStyles}
              options={machineList}
            />
          </FormGroup>
        </Col> */}
        <Col xs={12} sm={12} md={3} lg={4} xl={4} xxl={2}>
          <FormGroup>
            <label className="form-control-label textWhite d-block">
              Select Group:
            </label>
            <Select
              placeholder="Select Group..."
              name="group"
              value={{ value: filtersData.group, label: filtersData.group }}
              onChange={(e) => handleFilterChange('group', e.value)}
              styles={customStyles}
              options={groupList}
            />
          </FormGroup>
        </Col>
        <Col xs={12} sm={12} md={3} lg={4} xl={4} xxl={2}>
          <FormGroup>
            <label className="form-control-label textWhite d-block">
              Description:
            </label>
            <Select
              placeholder="Select Description..."
              name="description"
              value={{ value: filtersData.description, label: filtersData.description }}
              onChange={(e) => handleFilterChange('description', e.value)}
              styles={customStyles}
              options={descriptionList}
            />
          </FormGroup>
        </Col>
      </Row>
      {tableData === "Loading..." ? (
        <div className="row justify-content-center my-5">
          <Spinner>Loading...</Spinner>
        </div>
      ) : tableData.length > 0 ? (
        <>
          <Table
            values={tableData}
            columns={tableColumns}
            tableClasses={{
              table:
                "table align-items-center table-flush table-sm border-bottom text-white ",
              thead: "",
              tbody: "list table-td-color",
            }}
          />
          <TablePagination
            component="div"
            className="text-muted"
            count={count}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />{" "}
        </>
      ) : (
        <span className="m-4">No Data Found</span>
      )}
    </span>
  );
}

export default TableAlarmInfo;
